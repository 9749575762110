@import "../assets/styles/Variables.scss";

.sortable-wrapper {
  transition: 0.3s;
  margin-bottom: 20px;
  // -ms-touch-action: none;
  // touch-action: none;

  .icon-wrap {
    width: 50px;

    svg {
      fill: rgba($color: $SL-DarkBlue, $alpha: 0.5);
      transition: 0.3s;
    }
  }

  .detail-wrap {
    width: calc(100% - 50px);
    padding: 25px;
    background: $SL-White;
    border: 1px solid $SL-White;
    @include SL-Radius;
    transition: 0.3s;
    word-break: break-all;

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start !important;
    }

    .link-dtls {
      font-weight: $SL-Bold;
      padding-right: 30px;

      span {
        font-weight: $SL-Regular;
      }

      @media (max-width: 576px) {
        padding-bottom: 20px;
      }
    }

    p {
      font-weight: $SL-Regular;
    }

    .link-actions {
      span {
        width: 32px;
        height: 32px;
        transition: 0.3s;
        @include SL-Radius;

        &:first-child {
          margin-right: 25px;
        }

        svg {
          fill: $SL-DarkBlue;
          transition: 0.3s;
        }

        &:hover {
          cursor: pointer;
          background-color: $SL-DarkBlue;

          svg {
            fill: $SL-White;
          }
        }
      }
    }
  }

  &:hover {
    cursor: move;

    .icon-wrap {
      svg {
        fill: $SL-BtnYellow;
      }
    }

    .detail-wrap {
      border-color: rgba($color: $SL-BtnYellow, $alpha: 0.4);
      box-shadow: 0px 0px 9px #00000029;
    }
  }

  &.manage-buildings {
    .detail-wrap {
      width: 100%;
    }

    &:hover {
      cursor: default;

      .detail-wrap {
        border-color: $SL-White;
        box-shadow: inherit;
      }
    }
  }
}
