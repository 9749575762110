/**
 *@file SLDatePicker.scss
 *@brief Reusable date picker component style
 *@date Oct, 2024
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */
@import "../assets/styles/Variables.scss";

.sl-datepicker {
    margin-bottom: 17px;

    label {
        @include SL-FormLabel;
    }

    .react-datepicker-wrapper {
        width: 100%;

        input {
            background: url(../assets/img/ICCalendar.svg) 10px center no-repeat;
            width: 100%;
            @include SL-InputStyle;
            padding: 15px 16px 15px 40px;

            &:focus {
                border-color: $SL-BtnYellow !important;
                box-shadow: 0 0 0 0.25rem rgba($color: $SL-BtnYellow, $alpha: 0.3);
            }

            &:focus-visible {
                outline: none;
            }

            &.form-error {
                border-color: $SL-ErrorRed;

                &:focus {
                    box-shadow: 0 0 0 0.25rem rgba($color: $SL-ErrorRed, $alpha: 0.3);
                }
            }
        }
    }

    .error-msg {
        color: $SL-ErrorRed;
        font-size: $SL-Error-FS;
        margin-top: .25rem;
    }
}