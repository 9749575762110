@import '../assets/styles/Variables.scss';

.footer-inner {
    background: $SL-DarkBlue;
    padding: 25px;
    font-size: $SL-Copyright-FS;
    margin-top: 10px;
    color: rgba($color: $SL-White, $alpha: 0.5);
    @include SL-Radius;

    @media (max-width:992px){
        text-align: center;
        .col-lg-6{
            padding: 15px 0;
        }
    }

    a {
        color: rgba($color: $SL-White, $alpha: 0.5);

        &:hover {
            color: $SL-BtnYellow;
        }
    }
    

    .footer-links {
        span {
            padding: 0 10px;
        }
    }
}