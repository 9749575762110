@import './Variables.scss';

.login-wrap {
    padding: 80px 0;

    @media (max-width:576px) {
        padding: 20px;
    }

    .form-wrap {
        max-width: 672px;
        background: $SL-White;
        padding: 50px 120px;
        @include SL-BtnRadius;

        @media (max-width:576px) {
            padding: 20px;
        }

        h1 {
            font-weight: $SL-Bold;
            font-size: 34px;
            margin-bottom: 50px;

            span {
                font-size: 18px;
            }
        }

        .trouble-login {
            padding-top: 35px;

            a {
                font-size: $SL-Menu-FS;
                font-weight: $SL-Bold;
                color: $SL-DarkBlue;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

   
    .download-wrap {
        max-width: 672px;
        padding: 45px 0;

        p {
            font-size: 20px;
            font-weight: $SL-Medium;
            margin: 0;
        }

        .download-links {
            padding-top: 45px;

            span {
                width: 110px;
                height: 110px;
                background: $SL-White;
                border-radius: 15px;
                transition: 0.3s;
                margin: 0 10px;
                cursor: pointer;

                &:hover {
                    background: $SL-DarkBlue;
                }

                svg {
                    fill: $SL-DarkBlue;
                    transition: 0.3s;
                }

                &:hover {
                    svg {
                        fill: $SL-White;
                    }
                }
            }
        }
    }
}