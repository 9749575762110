@import "../assets/styles/Variables.scss";

.sidebar {
  background: $SL-White;
  width: 80px;
  transition: width 0.3s;
  height: calc(100vh - 87px);
  z-index: 10;

  @media (max-width: 1200px) {
    width: 340px;
    transition: 0.3s;
    transform: translatex(-340px);
  }

  @media (max-width: 576px) {
    width: 100%;
    transform: translatex(-100%);
  }

  .sidebar-inner {
    padding: 25px;
    height: 100%;

    a {
      font-weight: $SL-Medium;
      color: $SL-SidebarLinks;
      height: 66px;
      overflow: hidden;

      svg {
        fill: $SL-SidebarLinks;
        transition: 0.3s;
      }

      &:hover {
        color: $SL-DarkBlue;

        svg {
          fill: $SL-DarkBlue;
        }
      }

      &.active {
        color: $SL-DarkBlue;

        svg {
          fill: $SL-DarkBlue;
        }
      }

      &.support {
        display: none !important;

        @media (max-width: 1200px) {
          display: flex !important;
        }
      }
      //   @media (max-width: 576px) {
      //     height: 55px;
      //   }
      @supports (-webkit-touch-callout: none) {
        height: 55px;
      }
    }

    span {
      &.icon-wrap {
        padding-right: 25px;
      }

      &.link-item {
        transition: opacity 13ms ease-out;
        opacity: 0;

        @media (max-width: 1200px) {
          display: block;
          opacity: 1;
        }
      }
    }
  }

  &.slide-right {
    transform: translatex(0px);
    box-shadow: 0px 3px 6px #00000029;
    z-index: 99;
  }

  .menu-slide {
    width: 17px;
    height: 47px;
    right: -97px;
    background: $SL-White;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-radius: 0 5px 5px 0;
    cursor: pointer;

    @media (max-width: 1200px) {
      visibility: hidden;
    }

    &:hover {
      svg {
        fill: $SL-DarkBlue;
      }
    }

    svg {
      fill: $SL-CopyrightBorder;
    }
  }

  &.expand {
    width: 260px;
    transition: width 0.3s;

    .sidebar-inner {
      span {
        &.link-item {
          transition: opacity 1.3s ease-in;
          opacity: 1;
        }
      }
    }

    .menu-slide {
      right: -267px;

      &.collapse {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
