@import '../assets/styles/Variables.scss';

.inner-header {
    background: $SL-HeaderBg;
    padding: 20px;
    border-bottom: 1px solid rgba($color: $SL-DarkBlue, $alpha: .2);
    top: 0;
    z-index: 100;

    .logo-wrap {
        span {
            height: 46px;
            width: 46px;
            border-radius: 5px;
            display: none;
            background: url(../assets/img/ICHamburger.svg) center center no-repeat $SL-BtnYellow;
            margin-right: 10px;
            cursor: pointer;

            @media (max-width:1200px) {
                display: block;
            }

            &.close-icon {
                background: url(../assets/img/ICHamburger-Close.svg) center center no-repeat $SL-BtnYellow;
            }

        }

        img {
            cursor: pointer;
        }
    }

    .dropdown-wrap {
        .profile-img {
            width: 46px;
            height: 46px;
            border-radius: 23px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 23px;
                object-fit: cover;
            }
        }

        .username {
            color: #BE8900;
            font-weight: $SL-Medium;
            padding: 10px 25px;
            white-space: nowrap;
        }

        .dropdown-menu {
            text-align: center;
            border-color: $SL-BorderBlueDark;
            box-shadow: 0px 3px 6px #00000029;

            .dropdown-item {
                color: $SL-DarkBlue;
                padding: 10px 25px;
                font-weight: $SL-Medium;

                &:hover,
                &:focus,
                &:active {
                    background: $SL-BtnYellow;
                    color: $SL-White;
                }
            }
        }

        .dropdown-toggle {
            &::after {
                display: none;
            }
        }

        .menu-wrap {
            a {
                font-size: $SL-Menu-FS;
                color: $SL-DarkBlue;
                font-weight: $SL-Bold;
                padding-right: 30px;

                &:hover,
                &.active {
                    color: $SL-BtnYellow;
                }
            }

            @media (max-width:1200px) {
                display: none !important;
            }
        }
    }

    .emergency-icon {
        width: fit-content;
        height: 46px;
        border-radius: 23px;
        background: $SL-BtnYellow;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        padding: 0 20px;
        animation: pulse-animation 2s infinite;

        @media (max-width:576px) {
           width: 46px;
           padding: 0;
        }

        span {
            color: $SL-White;
            font-weight: $SL-Medium;
            padding-right: 10px;

            @media (max-width:576px) {
                display: none;
            }
        }

        @keyframes pulse-animation {
            0% {
                box-shadow: 0 0 0 0px rgba(175, 145, 65, 0.2);
            }

            100% {
                box-shadow: 0 0 0 20px rgba(175, 145, 65, 0);
            }
        }

        @media (max-width:576px) {
            right: -176px;
        }
    }
}