@import '../assets/styles/Variables.scss';

.main-wrapper {
    margin-top: 87px;

    .inner-wrapper {
        width: calc(100% - 80px);
        margin-left: 80px;
        padding: 30px;
        transition: margin 0.3s;

        &.menu-expand {
            width: calc(100% - 260px);
            margin-left: 260px;
            transition: margin 0.3s;
        }

        @media (max-width:576px) {
            padding: 20px;
        }

        @media (max-width:1200px) {
            width: 100%;
            margin-left: 0;
        }
    }
}