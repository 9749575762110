@import "../assets/styles/Variables.scss";

.login-header {
  background: $SL-HeaderBg;
  padding: 20px;
  border-bottom: 1px solid rgba($color: $SL-DarkBlue, $alpha: 0.2);

  .logo-wrap {
    img {
      cursor: default;
      &.pe-pointer {
        cursor: pointer;
      }
    }
  }

  .menu-wrap {
    a {
      color: $SL-DarkBlue;
      text-decoration: none;
      font-size: $SL-Menu-FS;
      font-weight: $SL-Medium;
      padding-right: 30px;

      &:hover,
      &:active,
      &:focus,
      &.active {
        color: $SL-BtnYellow;
      }
    }

    .btn {
      height: 46px;
      font-size: $SL-Primary-FS;
      font-weight: $SL-Medium;
      padding: 0 25px;

      @media (max-width: 576px) {
        display: none;
      }

      svg {
        fill: $SL-White;
        margin-right: 15px;
      }
    }

    .btn-secondary {
      background: $SL-BtnYellow;
      border-color: $SL-BtnYellow;
      @include SL-BtnRadius;

      &:hover {
        background-color: $SL-BtnYellow-Hover;
        border-color: $SL-BtnYellow-Hover;
      }

      &:active {
        background-color: $SL-BtnYellow-Hover !important;
        border-color: $SL-BtnYellow-Hover !important;
        box-shadow:
          inset 0 5px 11px 0 rgba($color: $SL-BtnYellow-Hover, $alpha: 0.5),
          0 4px 15px 0 rgba($color: $SL-BtnYellow-Hover, $alpha: 0.5) !important;
      }
    }

    .more-wrap {
      width: 46px;
      height: 46px;
      border-radius: 5px;
      background: $SL-BtnYellow;
    }

    .nav-item {
      display: none;

      @media (max-width: 576px) {
        display: block;
      }

      &.dropdown {
        .dropdown-toggle {
          padding: 0;

          &::after {
            display: none;
          }
        }
      }
    }

    .dropdown-menu {
      border-color: $SL-BorderBlueDark;
      box-shadow: 0px 3px 6px #00000029;

      .dropdown-item {
        color: $SL-DarkBlue;
        padding: 13px 18px;
        font-weight: $SL-Regular;

        &:hover {
          background: $SL-BtnYellow;
          color: $SL-White;

          svg {
            fill: $SL-White;
          }
        }

        svg {
          fill: $SL-DarkBlue;
          margin-right: 10px;
        }
      }
    }
  }
}
