/**
 *@file SLInput.scss
 *@brief Reusable input component style
 *@date Feb, 2024
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */
@import "../assets/styles/Variables.scss";

.sl-input {
  margin-bottom: 17px;

  .form-label {
    @include SL-FormLabel;
    img {
      margin-left: 5px;
    }
  }

  .form-control {
    @include SL-InputStyle;

    &.form-error {
      border-color: $SL-ErrorRed;

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba($color: $SL-ErrorRed, $alpha: 0.3);
      }
    }
  }

  &.has-icon {
    .form-control {
      padding-right: 53px;
    }
  }

  &.with-label {
    .icon-wrap {
      top: 32px;
    }
  }

  .icon-wrap {
    right: 13px;
    top: 0px;
    cursor: pointer;
    transition: 0.3s;
    height: 54px;

    svg {
      transition: 0.3s;
      fill: $SL-DarkBlue;

      path {
        &#round {
          fill: $SL-BtnYellow;
        }
      }
    }

    &:hover {
      svg {
        fill: $SL-White;

        path {
          &#round {
            fill: $SL-BtnYellow;
            opacity: 0.9;
          }
        }
      }
    }
  }

  .error-msg {
    color: $SL-ErrorRed;
    font-size: $SL-Error-FS;
    margin-top: 0.25rem;
  }

  .note-length {
    font-size: $SL-Menu-FS;
    color: $SL-DarkGrey;
    margin-left: auto;
    padding-top: 0.25rem;
  }
  .txtBxFocus {
    border-color: #e1ae29 !important;
    box-shadow: 0 0 0 0.25rem #e1ae294d;
  }
}
