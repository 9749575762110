/**
 *@file SLFileUpload.scss
 *@brief Reusable file upload component style
 *@date Feb, 2024
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */
 @import "../assets/styles/Variables.scss";

 .sl-input-file {
     margin-bottom: 17px;    
 
     &:active,
     &:focus {
         .custom-upload {
             transition: 0.3s;
             border-color: $SL-BtnYellow !important;
             box-shadow: 0 0 0 0.25rem rgba($color: $SL-BtnYellow, $alpha: 0.3);
             cursor: pointer;
         }
     }
 
     .form-label {
         @include SL-FormLabel;
 
         img {
             margin-left: 5px;    
 
             &:hover {
                 cursor: pointer;
                 fill: $SL-Tooltip-BG;
             }
         }
     }
 
     .form-control {
         @include SL-InputStyle;
         padding-top: 15px;
         padding-bottom: 15px;
         position: relative;
         background: transparent;
         z-index: 100;
         position: absolute;
         opacity: 0;
 
         &.form-error {
             border-color: $SL-ErrorRed;
 
             &:focus {
                 box-shadow: 0 0 0 0.25rem rgba($color: $SL-ErrorRed, $alpha: 0.3);
             }
         }
 
         &:hover {
             &::file-selector-button {
                 background-color: $SL-DarkBlue-Hover !important;
                 color: $SL-DarkBlue-Hover !important;
             }
         }
 
 
     }
 
     .custom-upload {
         @include SL-InputStyle;
         padding: 15px;
         position: relative;
         background: transparent;
         z-index: 10;
 
         &:hover,
         &:focus {
             border-color: $SL-BtnYellow !important;
             box-shadow: 0 0 0 0.25rem rgba($color: $SL-BtnYellow, $alpha: 0.3);
         }
 
         &.form-error {
             border-color: $SL-ErrorRed;
 
             &:focus {
                 box-shadow: 0 0 0 0.25rem rgba($color: $SL-ErrorRed, $alpha: 0.3);
             }
         }
 
         &:hover {
             &::file-selector-button {
                 background-color: $SL-DarkBlue-Hover !important;
                 color: $SL-DarkBlue-Hover !important;
             }
         }
         span{
             width: calc(100% - 100px);
             overflow: hidden;
             text-overflow: ellipsis;
         }
     }
 
     .error-msg {
         color: $SL-ErrorRed;
         font-size: $SL-Error-FS;
         margin-top: .25rem;
     }
 
     .browse-wrap {
         .browse {
             content: 'Browse';
             background: $SL-DarkBlue;
             border: none;
             transition: 1s;
             position: absolute;
             right: 6px;
             top: 6px;
             margin: 0;
             padding: 0 20px;
             height: 42px;
             color: $SL-DarkBlue;
             border-radius: 21px;
             width: 96px;
             display: flex;
             align-items: center;
             justify-content: center;
             color: $SL-White;
             font-weight: $SL-Bold;
             font-size: $SL-Primary-FS;
         }
     }
 
 }
 
 .form-control::file-selector-button {
     display: none;
 }