@import "~bootstrap/scss/bootstrap";
@import "./Variables.scss";

body {
  background: $SL-BodyBg;
  font-family: $SL-Font;
  color: $SL-DarkBlue;
  font-size: $SL-Primary-FS;
}

a,
button {
  transition: 0.3s;
  text-decoration: none;
}

//Form Style
.form-control,
.form-select {
  &:focus {
    border-color: $SL-BtnYellow !important;
    box-shadow: 0 0 0 0.25rem rgba($color: $SL-BtnYellow, $alpha: 0.3);
  }
}

//Button Style
.btn {
  &:disabled {
    background-color: $SL-InputGrey;
    border-color: $SL-InputGrey;
  }
}

.btn-primary {
  background: $SL-DarkBlue;
  border-color: $SL-DarkBlue;
  height: 54px;
  border-radius: 27px;
  font-size: $SL-Button-FS;
  font-weight: $SL-Bold;
  padding: 0 25px;

  &:hover {
    background-color: $SL-DarkBlue-Hover;
    border-color: $SL-DarkBlue-Hover;
  }

  &:active {
    background-color: $SL-DarkBlue-Hover !important;
    border-color: $SL-DarkBlue-Hover !important;
    box-shadow:
      inset 0 5px 11px 0 rgba($color: $SL-DarkBlue, $alpha: 0.5),
      0 4px 15px 0 rgba($color: $SL-DarkBlue, $alpha: 0.5) !important;
  }
}

.btn-outline-primary {
  background: $SL-White;
  border-color: $SL-BorderBlue;
  color: $SL-DarkBlue;
  height: 54px;
  border-radius: 27px;
  font-size: $SL-Button-FS;
  font-weight: $SL-Bold;
  padding: 0 25px;

  &:hover {
    background-color: $SL-White;
    border-color: $SL-DarkBlue;
    color: $SL-DarkBlue;
  }

  &:active {
    color: $SL-DarkBlue !important;
    background-color: $SL-White !important;
    border-color: $SL-DarkBlue !important;
    box-shadow:
      inset 0 5px 11px 0 rgba($color: $SL-DarkGrey-Hover, $alpha: 0.09),
      0 4px 15px 0 rgba($color: $SL-DarkGrey-Hover, $alpha: 0.2) !important;
  }
}

.btn-outline-secondary {
  background: $SL-White;
  border-color: $SL-DarkBlue;
  color: $SL-DarkBlue;
  min-width: 120px;
  height: 54px;
  border-radius: 27px;
  font-size: $SL-Button-FS;
  font-weight: $SL-Bold;
  padding: 0 25px;

  &:hover {
    background-color: $SL-White;
    border-color: $SL-DarkBlue;
    color: $SL-DarkBlue;
  }

  &:active {
    color: $SL-DarkBlue !important;
    background-color: $SL-White !important;
    border-color: $SL-DarkBlue !important;
    box-shadow:
      inset 0 5px 11px 0 rgba($color: $SL-DarkGrey-Hover, $alpha: 0.09),
      0 4px 15px 0 rgba($color: $SL-DarkGrey-Hover, $alpha: 0.2) !important;
  }
}

.btn-secondary {
  background: $SL-DarkGrey;
  border-color: $SL-DarkGrey;
  height: 54px;
  border-radius: 27px;
  font-size: $SL-Button-FS;
  font-weight: $SL-Bold;
  padding: 0 25px;

  &:hover {
    background-color: $SL-DarkGrey-Hover;
    border-color: $SL-DarkGrey-Hover;
  }

  &:active {
    background-color: $SL-DarkGrey-Hover !important;
    border-color: $SL-DarkGrey-Hover !important;
    box-shadow:
      inset 0 5px 11px 0 rgba($color: $SL-DarkGrey, $alpha: 0.5),
      0 4px 15px 0 rgba($color: $SL-DarkGrey, $alpha: 0.5) !important;
  }
}

.btn-info {
  background: $SL-DarkGrey-Hover;
  border-color: $SL-DarkGrey-Hover;
  height: 54px;
  border-radius: 27px;
  font-size: $SL-Button-FS;
  font-weight: $SL-Bold;
  padding: 0 25px;
  color: $SL-White;

  &:hover {
    background-color: #1b1d21;
    border-color: #1b1d21;
    color: $SL-White;
  }

  &:active {
    background-color: #1b1d21 !important;
    color: $SL-White !important;
    border-color: #1b1d21 !important;
    box-shadow:
      inset 0 5px 11px 0 rgba($color: #1b1d21, $alpha: 0.5),
      0 4px 15px 0 rgba($color: #1b1d21, $alpha: 0.5) !important;
  }
}

//Alert Style
.alert {
  .btn-close {
    background-size: 10px;
  }
}

//Content Style
.inner-wrapper {
  .tilte-wrapper {
    padding-bottom: 30px;

    .back {
      margin-bottom: 15px;
      transition: 0.3s;
      width: fit-content;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }

    h1 {
      font-size: $SL-H1-FS;
      font-weight: $SL-Bold;

      @media (max-width: 992px) {
        flex-wrap: wrap;
      }

      span {
        font-size: $SL-Error-FS;
        font-weight: bold;
        letter-spacing: 1px;

        &.end-session {
          padding-left: 15px;
          font-weight: $SL-Regular;
          letter-spacing: inherit;
          font-size: $SL-Menu-FS;

          @media (max-width: 576px) {
            padding-left: 0;
          }

          a {
            font-weight: $SL-Bold;
            color: rgb(134, 136, 142);

            &:hover,
            &:focus,
            &:active {
              text-decoration: underline;
            }
          }
        }
      }

      .badge {
        height: 38px;
        border-radius: 19px;
        margin-left: 15px;
        font-size: $SL-Menu-FS;
        font-weight: $SL-Bold;
        padding: 12px 15px;

        @media (max-width: 576px) {
          margin-left: 0;
          margin-top: 15px;
          margin-bottom: 15px;
        }

        &.afterhour {
          background: $SL-LightBlue !important;
        }

        &.emergency {
          background: $SL-BtnYellow !important;
        }
      }
    }

    .add-users {
      height: 54px;
      background: $SL-White;
      border: 1px solid $SL-BorderBlue;
      padding: 0 15px;
      font-size: $SL-Button-FS;
      font-weight: $SL-Medium;
      @include SL-Radius;
      transition: 0.3s;

      svg {
        fill: $SL-DarkBlue;
        transition: 0.3s;
        margin-left: 10px;
      }

      &:hover {
        background-color: $SL-White;
        border-color: $SL-DarkBlue;
        color: $SL-DarkBlue;
        cursor: pointer;
      }

      &:active {
        color: $SL-DarkBlue !important;
        background-color: $SL-White !important;
        border-color: $SL-DarkBlue !important;
        box-shadow:
          inset 0 5px 11px 0 rgba($color: $SL-DarkGrey-Hover, $alpha: 0.09),
          0 4px 15px 0 rgba($color: $SL-DarkGrey-Hover, $alpha: 0.2) !important;
      }
    }

    .btn {
      @media (max-width: 576px) {
        width: 100%;
        margin-top: 12px;
      }
    }
  }

  h3 {
    font-size: $SL-Button-FS;
    font-weight: $SL-Bold;
    margin-bottom: 30px;

    &.organization {
      margin-bottom: 0;

      @media (max-width: 576px) {
        margin-bottom: 17px;
      }
    }

    span {
      font-size: $SL-Error-FS;
      font-weight: $SL-Regular;
      line-height: 22px;
    }
  }

  .action-wrapper {
    @media (max-width: 576px) {
      padding-bottom: 17px;
    }

    .btn-outline-primary {
      margin-right: 20px;

      @media (max-width: 576px) {
        margin: 0 0 17px 0;
      }
    }

    .btn-outline-secondary {

      @media (max-width: 576px) {
        margin: 0 0 17px 0;
      }
    }

    .btn {
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    &.user-search {
      .sl-input {
        .form-control {
          padding-right: 0.75rem;
        }

        .icon-wrap {
          display: none !important;
        }
      }
    }

    .filter {
      .btn-outline-secondary {
        &::before {
          content: "Filter";
        }

        svg {
          display: none;
        }
      }
    }

    .filter-on {
      .btn-outline-secondary {
        background: $SL-Filteron;
        border-color: $SL-Filteron;
        display: flex;
        align-items: center;

        &::before {
          content: "Filter On";
        }

        &:hover {
          background-color: $SL-BtnYellow;
          border-color: $SL-BtnYellow;
          color: $SL-DarkBlue;
        }

        &:active {
          color: $SL-DarkBlue !important;
          background-color: $SL-BtnYellow !important;
          border-color: $SL-BtnYellow !important;
          box-shadow:
            inset 0 5px 11px 0 rgba($color: $SL-DarkGrey-Hover, $alpha: 0.09),
            0 4px 15px 0 rgba($color: $SL-DarkGrey-Hover, $alpha: 0.2) !important;
        }

        svg {
          width: 24px;
          height: 24px;
          fill: $SL-DarkBlue;
          margin-left: 10px;
        }
      }
    }
  }

  h2 {
    font-size: $SL-Button-FS;
    font-weight: $SL-Bold;
    margin-bottom: 30px;
  }

  .content-wrapper {
    background: $SL-White;
    padding: 30px;
    margin-bottom: 30px;
    @include SL-Radius;

    .btn {
      min-width: 110px;
    }

    &.dashboard {
      font-size: $SL-Menu-FS;
      padding: 25px;

      h5 {
        font-size: $SL-H1-FS;
        font-weight: $SL-Medium;
        margin-bottom: 10px;

        span {
          font-size: $SL-Menu-FS;
          font-weight: $SL-Bold;
          margin-bottom: 4px;
        }
      }

      &.welcome {
        font-size: $SL-Button-FS;
        font-weight: $SL-Medium;

        p {
          font-size: $SL-Menu-FS;
          font-weight: $SL-Regular;
          margin-bottom: 10px;

          span {
            color: $SL-BtnYellow;
            font-weight: $SL-Bold;
          }
        }

        h3 {
          font-size: $SL-Button-FS;
          font-weight: $SL-Bold;
          margin-bottom: 0;

          span {
            font-size: $SL-Primary-FS;
            font-weight: $SL-Regular;
            padding-top: 10px;
          }
        }

        ul {
          list-style: none;
          padding-left: 20px;

          li {
            position: relative;
            font-size: $SL-Primary-FS;
            font-weight: $SL-Regular;
            margin-top: 13px;

            &::before {
              content: " ";
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background: $SL-BtnYellow;
              display: block;
              position: absolute;
              top: 7px;
              left: -20px;
            }

            a {
              color: $SL-BtnYellow;
              font-weight: $SL-Medium;
              text-decoration: underline;

              &:hover,
              &:focus,
              &:active {
                text-decoration: none;
              }
            }
          }
        }

        .copy-link {
          @media (max-width: 992px) {
            padding-top: 20px;
          }

          .copy-btn {
            width: 54px;
            height: 54px;
            background: $SL-DarkBlue;
            @include SL-Radius;
            margin-left: 6px;

            &:hover {
              cursor: pointer;
              background-color: $SL-DarkBlue-Hover;
              border-color: $SL-DarkBlue-Hover;
            }

            &:active {
              background-color: $SL-DarkBlue-Hover !important;
              border-color: $SL-DarkBlue-Hover !important;
              box-shadow:
                inset 0 5px 11px 0 rgba($color: $SL-DarkBlue, $alpha: 0.5),
                0 4px 15px 0 rgba($color: $SL-DarkBlue, $alpha: 0.5) !important;
            }

            svg {
              fill: $SL-White;
            }
          }

          .sl-input {
            width: calc(100% - 60px);
            margin-bottom: 0;

            .form-control {
              background: $SL-Form-Autofill-BG;
            }
          }
        }

        .users-info-txt {
          @media (max-width: 992px) {
            padding-top: 20px;
          }

          p {
            margin: 0;
          }
        }

        &.support {
          background: url(../img/ICSupportBg.svg) center right 20px no-repeat $SL-White;
          padding: 50px 110px 50px 25px;
        }
        &.users-info{
          background: none;          
        }
      }

      &.app-display{
        button{
          padding-left: 16px;
          margin-right: 17px;
          @media(max-width:576px){
            width: 100%;
            margin-right: 0;
            margin-bottom: 17px;
          }
          svg{
            fill: $SL-White;
            margin-right: 8px;
            width: 26px;
          }
        }
      }

      &.summary-items {
        border: 1px solid $SL-White;
        transition: 0.3s;

        &:hover {
          cursor: pointer;
          transition: 0.3s;
          border: 1px solid rgba($color: $SL-DarkGrey, $alpha: 0.2);
          box-shadow: 0 0 11px rgba($color: $SL-DarkGrey, $alpha: 0.2);
          transform: translateY(-5px);
        }

        &.emergency {
          background:
            url(../img/ICEmergencyBg.svg) 20px center no-repeat,
            url(../img/ICEmergencyArrow.svg) right 20px center no-repeat $SL-White;
          padding-left: 140px;
          padding-right: 60px;
          min-height: 165px;

          @media (max-width: 576px) {
            padding-left: 120px;
          }

          p {
            max-width: 275px;
          }
        }

        .left-wrap {
          .icon-wrap {
            height: 45px;
            margin-bottom: 20px;
          }
        }

        .right-wrap {
          font-family: $SL-Secondary-Font;
          font-size: $SL-DashCount-FS;
          font-weight: $SL-ExtraBold;

          &.blue {
            color: $SL-LightBlue;
          }
        }
      }
    }

    &.buildings {
      font-weight: $SL-Regular !important;
      font-size: $SL-Primary-FS !important;

      .has-icon {
        @media (max-width: 576px) {
          margin-top: 17px;
        }
      }

      .add-building {
        @media (max-width: 576px) {
          flex-direction: column;
        }

        .btn {
          margin-right: 32px;

          @media (max-width: 576px) {
            width: 100%;
            margin-right: 0;
          }
        }

        .sl-input {
          width: 350px;

          @media (max-width: 576px) {
            width: 100%;
          }
        }
      }

      .manage-buildings {
        .detail-wrap {
          background: $SL-BodyBg;
        }
      }
    }

    textarea {
      height: 115px;
    }

    .start-end-wrap {
      justify-content: end;
      align-items: center;

      @media (max-width: 992px) {
        justify-content: start;
        padding-top: 30px;
      }

      @media (max-width: 576px) {
        flex-direction: column;
        align-items: start;
      }

      .start-end-dtls {
        width: 202px;
        height: 120px;
        background: $SL-DarkBlue;
        color: $SL-White;
        font-size: $SL-Menu-FS;
        font-weight: $SL-Bold;
        padding: 15px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-transform: uppercase;
        line-height: 31px;
        @include SL-Radius;

        span {
          font-weight: $SL-Regular;
          font-size: 14px;
          text-transform: lowercase;
        }

        @media (max-width: 576px) {
          width: 100%;
          text-align: center;
        }

        &:first-child {
          margin-right: 15px;

          @media (max-width: 576px) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .btn-wrap {
    .btn {
      @media (max-width: 576px) {
        width: 100%;

        &:first-child {
          @media (max-width: 576px) {
            margin-bottom: 20px;
          }
        }
      }

      &.btn-secondary {
        margin-right: 20px;

        @media (max-width: 576px) {
          margin-right: 0px;
        }
      }
    }
  }

  .imp-links-wrapper {
    min-height: 108px;
    max-height: 400px;
  }
}

.terms_conditions {
  width: 100%;
  padding: 30px;

  .tilte-wrapper {
    padding-bottom: 15px;

    h1 {
      font-size: $SL-H1-FS;
      font-weight: $SL-Bold;

      @media (max-width: 992px) {
        flex-wrap: wrap;
      }

      span {
        font-size: $SL-Error-FS;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
  }

  .imgWdth {
    max-width: 172px;
  }

  h3 {
    font-size: $SL-Button-FS;
    font-weight: $SL-Bold;
    margin-bottom: 0;
    margin-bottom: 20px !important;
    margin-top: 30px !important;
  }

  h4 {
    font-size: $SL-Button-FS;
    font-weight: $SL-Bold;
    margin-bottom: 0;
    margin-bottom: 20px !important;
  }

  a {
    color: #5c89e1;
    text-decoration: underline;

    &:hover {
      color: #e1ae29;
      text-decoration: none;
    }
  }
}

//Need Help Style
.need-help {
  padding: 20px 0 50px 0;

  span {
    background: url(../img/ICHelp.svg) 35px center no-repeat $SL-White;
    width: 215px;
    height: 78px;
    border-radius: 39px;
    font-size: $SL-Button-FS;
    font-weight: $SL-Bold;
    padding-left: 30px;
    box-shadow: 0px 3px 6px #00000029;
    transition: 0.3s;

    &:hover,
    &:active,
    &:focus {
      background: url(../img/ICHelp.svg) 35px center no-repeat $SL-BtnYellow;
      cursor: pointer;
      transform: translateY(-5px);
    }
  }
}

//Table Style{
.table {
  font-size: $SL-Menu-FS;

  thead {
    tr {
      border-color: rgba($color: $SL-DarkBlue, $alpha: 0.06);

      th {
        padding: 20px;
        font-weight: $SL-Bold;
        color: $SL-DarkBlue;
        white-space: nowrap;

        &:first-child {
          border-radius: 8px 0 0 0;
        }

        &:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }
  }

  tbody {
    &.vertical-middle {
      vertical-align: middle;
    }

    tr {
      border-color: rgba($color: $SL-DarkBlue, $alpha: 0.06);

      &:hover,
      &:active,
      &:focus {
        td {
          background: $SL-TableHover;
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-radius: 0 0 0 8px;
          }

          &:last-child {
            border-radius: 0 0 8px 0;
          }
        }
      }

      td {
        transition: 0.3s;
        padding: 20px;
        color: $SL-DarkBlue;

        &.toggle {
          width: 85px;

          span {
            width: 20px;
            height: 20px;
            cursor: pointer;

            &.tableCollapse {
              svg {
                transform: rotate(90deg);
              }
            }

            &.disabled {
              opacity: 0.4;
              cursor: not-allowed;
            }
          }
        }

        &.user {
          a {
            font-weight: $SL-Medium;
            text-decoration: underline;
            color: $SL-DarkBlue;

            &:hover {
              text-decoration: none;
            }
          }
        }

        &.messages {
          max-width: 220px;

          span {
            width: calc(100% - 10px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &.expand {
            span {
              overflow: inherit;
              text-overflow: inherit;
              white-space: inherit;
            }
          }
        }

        &.addnote {
          max-width: 350px;

          a {
            font-weight: $SL-Medium;
            text-decoration: underline;
            color: $SL-DarkBlue;

            &:hover {
              text-decoration: none;
            }
          }

          button {
            width: 56px;
            height: 29px;
            font-size: $SL-Copyright-FS;
            font-weight: $SL-Medium;
            padding: 0;
            margin-top: 20px;
          }

          span {
            width: calc(100% - 10px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &.expand {
            span {
              overflow: inherit;
              text-overflow: inherit;
              white-space: inherit;
            }
          }
        }

        &.ic-yellow {
          text-align: center;
          width: 40px;

          svg {
            fill: $SL-BtnYellow;
            transition: 0.3s;
            cursor: pointer;

            &:hover {
              fill: $SL-DarkGrey;
            }
          }
        }

        &.disabled-ic {
          pointer-events: none;

          svg {
            fill: $SL-InputGrey;
          }
        }

        &.ic-blue {
          text-align: center;
          width: 40px;

          svg {
            fill: $SL-DarkBlue;
            transition: 0.3s;
            cursor: pointer;

            &:hover {
              fill: $SL-DarkGrey;
            }
          }
        }

        .temp-id {
          font-weight: $SL-ExtraBold;
          padding-left: 2px;
          cursor: pointer;
        }

        &.unresponsive {
          color: $SL-BtnYellow;
          font-weight: $SL-Bold;
        }

        &.safe {
          color: $SL-DarkGrey;
          font-weight: $SL-Bold;
        }

        &.needassistance {
          font-weight: $SL-Bold;
        }

        &:hover {
          background: $SL-TableHover;
        }

        &.tb-switch-center {
          .form-switch {
            display: flex;
            justify-content: center;
          }
        }

        .btn-primary {
          height: 28px;
          font-size: $SL-Copyright-FS;
          font-weight: $SL-Regular;
          padding: 0 15px;
          white-space: nowrap;
        }

        .rally-image {
          width: 46px;
          height: 46px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include SL-Radius;
          }
        }
      }
    }
  }
}

//Switch Style
.form-switch {
  .form-check-input {
    width: 34px;
    height: 20px;
    border-radius: 10px;
    background-color: $SL-InputGrey;
    border: none;
    cursor: pointer;
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386888E'/%3e%3c/svg%3e");

    &:checked {
      background-color: $SL-DarkBlue;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
    }
  }

  label {
    padding: 2px 0 0 8px;
  }
}

//Pagination Style
.pagination-wrapper {
  margin-top: 20px;

  .pagination {
    @media (max-width: 576px) {
      margin: auto;
    }

    .page-item {
      white-space: nowrap;

      .page-link {
        background-color: inherit;
        color: $SL-DarkBlue;
        padding: 10px;
        border: none;

        &:hover {
          text-decoration: underline;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &.active {
        .page-link {
          color: $SL-BtnYellow;
          font-weight: $SL-Bold;
        }
      }
    }

    .vertical-divider-start {
      padding-left: 10px;
    }

    .vertical-divider-end {
      padding-right: 10px;
    }
  }

  .show-pages {
    color: $SL-DarkBlue;
    padding-top: 10px;

    @media (max-width: 576px) {
      text-align: center;
    }

    a {
      color: $SL-DarkBlue;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        color: $SL-BtnYellow;
        font-weight: $SL-Bold;
      }
    }
  }
}

//Support Links{
.support-links-wrap {
  margin-bottom: 25px;

  @media (max-width: 1200px) {
    margin-bottom: 0;
  }

  .error-msg {
    color: $SL-ErrorRed;
    font-size: $SL-Error-FS;
    margin-top: 0.25rem;
  }

  &.error {
    .support-links {
      border: 1px solid $SL-ErrorRed;

      &:hover,
      &:focus,
      &:active {
        cursor: pointer;
        border-color: $SL-ErrorRed;
      }
    }
  }

  .support-links {
    padding: 35px 35px 35px 105px;
    background: $SL-White;
    border: 2px solid $SL-White;
    font-size: $SL-Button-FS;
    color: $SL-DarkBlue;
    font-weight: $SL-Medium;
    min-height: 126px;
    transition: 0.3s;
    position: relative;
    @include SL-Radius;

    @media (max-width: 1200px) {
      margin-bottom: 25px;
    }

    &.bug {
      background: url(../img/ICBug.svg) center left 35px no-repeat $SL-White;
    }

    &.billing {
      background: url(../img/ICBilling.svg) center left 35px no-repeat $SL-White;
    }

    &.feedback {
      background: url(../img/ICFeedback.svg) center left 35px no-repeat $SL-White;
    }

    &.other {
      background: url(../img/ICOther.svg) center left 35px no-repeat $SL-White;
    }

    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
      border-color: #dab84b;
    }

    &.active {
      border-color: #dab84b;

      &::after {
        content: " ";
        width: 18px;
        height: 14px;
        position: absolute;
        background: url(../img/SupportLinkActive.svg) 0 0 no-repeat;
        right: 20px;
        top: 20px;
      }
    }
  }
}

//Modal Style
.modal-content {
  border-color: $SL-White;

  .modal-header {
    padding: 30px;
    border: none;

    .modal-title {
      font-size: $SL-Button-FS;
      font-weight: $SL-Bold;

      .form-check {
        margin-left: 32px;
      }
    }

    .btn-close {
      background: url(../img/ICModalClose.svg) center center no-repeat;
      width: 24px;
      height: 24px;
      opacity: 1 !important;

      &:hover,
      &:active,
      &:focus {
        opacity: 0.5 !important;
        box-shadow: none;
      }
    }
  }

  .modal-body {
    padding: 0 30px;

    &.notes {
      textarea {
        height: 350px;
        resize: none;
      }
    }

    &.alert-info {
      padding-top: 20px;
      padding-bottom: 40px;
      font-size: $SL-Modal-Alert-Txt;

      p {
        padding-bottom: 30px;
        margin-bottom: 0;

        a {
          color: #dab84b;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &.view-details {
      padding-bottom: 15px;
    }

    &.add-user {
      .admin {
        @media (max-width: 576px) {
          margin-top: 20px;
        }
      }

      p {
        font-size: 14px;
        margin-top: 32px;
        color: $SL-DarkGrey;
      }
    }
  }

  .modal-footer {
    padding: 0 30px 30px;
    border: none;

    .btn {
      min-width: 110px;
    }
  }
}

//Accordion Style
.accordion {
  margin-bottom: 30px;

  .accordion-item {
    border: none;
    margin-bottom: 7px;
    @include SL-Radius;

    .accordion-header {
      margin-bottom: 0;

      .accordion-button {
        font-size: $SL-Button-FS;
        font-weight: $SL-Bold;
        background: transparent;
        box-shadow: none;
        color: $SL-DarkBlue-Hover;

        &::after {
          width: 28px;
          height: 28px;
          background: url(../img/ICAccordion.svg) 0 0 no-repeat !important;
          display: block;
        }

        &:focus {
          box-shadow: inherit;
        }
      }

      .badge {
        min-width: 60px;
        height: 34px;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $SL-Button-FS;
        font-weight: $SL-Bold;
        background: $SL-DarkBlue !important;
        margin-left: 20px;
      }

      &.need-assistance {
        .accordion-button {
          color: $SL-BtnYellow;
        }

        .badge {
          background: $SL-BtnYellow !important;
        }
      }

      &.safe {
        .accordion-button {
          font-weight: $SL-Medium;
        }

        .badge {
          background: rgba($color: $SL-DarkBlue, $alpha: 0.5) !important;
        }
      }
    }

    .accordion-body {
      &.instructions {
        padding-top: 0;

        ul {
          list-style: none;
          padding-left: 20px;

          li {
            position: relative;
            font-size: $SL-Primary-FS;
            font-weight: $SL-Regular;
            margin-top: 13px;
            color: $SL-DarkBlue-Hover;

            &::before {
              content: " ";
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background: $SL-BtnYellow;
              display: block;
              position: absolute;
              top: 7px;
              left: -20px;
            }

            a {
              color: $SL-BtnYellow;
              font-weight: $SL-Medium;
              text-decoration: underline;

              &:hover,
              &:focus,
              &:active {
                text-decoration: none;
              }
            }

            span {
              font-weight: $SL-Bold;
            }
          }
        }
      }
    }
  }
}

//Common Style Reset
.cutom-table-view {
  .table-row {
    display: flex;
    margin-bottom: 10px;

    @media (max-width: 576px) {
      flex-direction: column;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .table-column {
      font-size: $SL-Menu-FS;

      &:first-child {
        min-width: 160px;
        padding-right: 30px;
        font-weight: $SL-Bold;
      }
    }
  }
}

textarea {
  resize: none;
}

.no-record {
  min-height: calc(100vh - 470px);
  padding-top: 20px;
}

.label-common {
  @include SL-FormLabel;
}

.checkin-intervals {
  select {
    width: 85px;
    height: 40px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  p {
    font-size: $SL-Menu-FS;
    padding: 30px 0 0 15px;

    span {
      color: $SL-DarkBlue !important;
      font-weight: $SL-Bold;
    }
  }
}

.rally-img-preview {
  width: 46px;
  height: 46px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include SL-Radius;
  }
}

.start-end-group {
  label {
    @include SL-FormLabel;
    margin-bottom: 0.5rem;
  }

  .form-select {
    background: url(../img/ICTime.svg) 10px center no-repeat;
    padding-left: 40px;
    padding-right: 16px;
  }
}

.remove-user {
  align-items: flex-end;

  @media (max-width: 576px) {
    margin-top: 24px;
  }

  .btn {
    height: 28px;
    font-size: $SL-Copyright-FS;
    font-weight: $SL-Regular;
    min-width: 100px;
    padding-left: 12px;
    padding-right: 12px;

    svg {
      margin-left: 8px;
      fill: $SL-White;
    }
  }
}

.org-img-preview {
  width: 54px;
  height: 54px;
  margin-top: 34px;

  @media (max-width: 992px) {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include SL-Radius;
  }
}

.tooltip.show {
  opacity: 1;
}

.tooltip>.tooltip-inner {
  background-color: $SL-Tooltip-BG !important;
  color: $SL-White !important;
  text-wrap: wrap !important;
  max-width: 120px;
  font-size: $SL-Copyright-FS;
  opacity: 1 !important;
}

.tooltip.bs-tooltip-bottom>.tooltip-arrow::before,
.color-tooltip.bs-tooltip-auto[data-popper-placement^="bottom"]>.tooltip-arrow::before {
  border-bottom-color: $SL-Tooltip-BG !important;
}

.tooltip.bs-tooltip-top>.tooltip-arrow::before,
.color-tooltip.bs-tooltip-auto[data-popper-placement^="top"]>.tooltip-arrow::before {
  border-top-color: $SL-Tooltip-BG !important;
}

.tooltip.bs-tooltip-start>.tooltip-arrow::before,
.color-tooltip.bs-tooltip-auto[data-popper-placement^="left"]>.tooltip-arrow::before {
  border-left-color: $SL-Tooltip-BG !important;
}

.tooltip.bs-tooltip-end>.tooltip-arrow::before,
.color-tooltip.bs-tooltip-auto[data-popper-placement^="right"]>.tooltip-arrow::before {
  border-right-color: $SL-Tooltip-BG !important;
}

.toast-message-width-500 {
  width: 500px !important;
}

.popover {
  background: $SL-Tooltip-BG;
  border-color: $SL-Tooltip-BG;

  .popover-body {
    color: $SL-White;
  }
}

.bs-popover-top>.popover-arrow::after {
  border-top-color: $SL-Tooltip-BG;
}

.bs-popover-bottom>.popover-arrow::after {
  border-bottom-color: $SL-Tooltip-BG;
}

.bs-popover-end>.popover-arrow::after {
  border-right-color: $SL-Tooltip-BG;
}

.bs-popover-start>.popover-arrow::after {
  border-left-color: $SL-Tooltip-BG;
}

.form-check-input {
  border-color: $SL-DarkBlue !important;

  &:checked {
    background-color: $SL-DarkBlue;
    border-color: $SL-DarkBlue;
  }
}