//Font
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
$SL-Font: "Roboto",
sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
$SL-Secondary-Font: "Montserrat",
sans-serif;

//Colors
$SL-BodyBg: #f8f8f8;
$SL-DarkBlue: #18345a;
$SL-DarkBlue-Hover: #224d87;
$SL-InputGrey: #cbcbcb;
$SL-LightBlue: #5c89e1;
$SL-LightBlueHover: #6999f6;
$SL-DarkGrey: #86888e;
$SL-DarkGrey-Hover: #313541;
$SL-BorderBlue: #acbbd1;
$SL-BorderBlueDark: #8ba1bf;
$SL-White: #ffffff;
$SL-HeaderBg: #f9fcff;
$SL-BtnYellow: #e1ae29;
$SL-BtnYellow-Hover: #f3bd30;
$SL-FooterGrey: #9e9e9e;
$SL-CopyrightBorder: #707070;
$SL-SidebarLinks: #7086a5;
$SL-ErrorRed: #dc3545;
$SL-TableHover: #f4f6f9;
$SL-Form-Autofill-BG: #fffee2;
$SL-Tooltip-BG: #485e7c;
$SL-CheckIn-IC-Bg: #dab84b;
$SL-Filteron:#EFDDB0;

//Font Size
$SL-Error-FS: 12px;
$SL-Copyright-FS: 13px;
$SL-Menu-FS: 15px;
$SL-Primary-FS: 16px;
$SL-Button-FS: 18px;
$SL-Modal-Alert-Txt: 20px;
$SL-H1-FS: 22px;
$SL-DashCount-FS: 41px;

//Font Weights
$SL-Thin: 100;
$SL-Light: 300;
$SL-Regular: 400;
$SL-Medium: 500;
$SL-Bold: 700;
$SL-ExtraBold: 800;
$SL-Black: 900;

//Radius Mixins
@mixin SL-Radius() {
  border-radius: 8px;
}

@mixin SL-BtnRadius() {
  border-radius: 24px;
}

@mixin SL-InputStyle {
  border: 1px solid $SL-InputGrey;
  height: 54px;
  font-size: $SL-Menu-FS;
  @include SL-Radius;
}

@mixin SL-FormLabel {
  font-size: $SL-Menu-FS;
  font-weight: $SL-Bold;
}