@import '../assets/styles/Variables.scss';

.checkin-wrap {
    background: $SL-White;
    padding: 22px;
    align-items: center;
    justify-content: space-between;
    margin: 4px 0 0 0;
    @include SL-Radius;
    transition: 0.3s;

    @media (max-width:596px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .left-wrap {
        font-size: $SL-Menu-FS;

        span {
            font-weight: $SL-Bold;
        }
    }

    .right-wrap {
        font-weight: $SL-Bold;
        color: $SL-DarkGrey;

        @media (max-width:596px) {
            flex-direction: column;
        }

        span {
            &.active-dtls {
                svg {
                    fill: $SL-DarkGrey;
                    margin-left: 10px;
                    margin-right: 30px;

                    @media (max-width:596px) {
                        margin-right: 0;
                    }
                }
            }

            &.icon-wrap {
                svg {
                    rect {
                        fill: $SL-DarkGrey;
                    }

                    path {
                        stroke: $SL-White;
                    }
                }
            }
        }
    }

    &.active {
        background-color: $SL-LightBlue;
        color: $SL-White;

        .right-wrap {
            color: $SL-White;

            span {
                &.active-dtls {

                    svg {
                        fill: $SL-CheckIn-IC-Bg;
                    }
                }

                &.icon-wrap {
                    svg {
                        rect {
                            fill: $SL-White;
                        }

                        path {
                            stroke: $SL-DarkBlue;
                        }
                    }
                }
            }
        }

        &:hover {
            background-color: $SL-LightBlueHover;
        }
    }

    &.emergency-active {
        background-color: $SL-DarkBlue;
        color: $SL-White;

        .right-wrap {
            color: $SL-White;

            span {
                &.active-dtls {

                    svg {
                        fill: $SL-CheckIn-IC-Bg;
                    }
                }

                &.icon-wrap {
                    svg {
                        rect {
                            fill: $SL-White;
                        }

                        path {
                            stroke: $SL-DarkBlue;
                        }
                    }
                }
            }
        }

        &:hover {
            background-color: $SL-DarkBlue-Hover;
        }
    }

    &:hover {
        cursor: pointer;
        background: $SL-TableHover;
    }
}