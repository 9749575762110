/**
 *@file SLFileUpload.scss
 *@brief Reusable file upload component style
 *@date Feb, 2024
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */
@import "../assets/styles/Variables.scss";

.sl-select {
    margin-bottom: 17px;

    .form-label {
        @include SL-FormLabel;
    }

    .form-select {
        @include SL-InputStyle;
        padding-top: 15px;
        padding-bottom: 15px;

        &.form-error {
            border-color: $SL-ErrorRed;

            &:focus {
                box-shadow: 0 0 0 0.25rem rgba($color: $SL-ErrorRed, $alpha: 0.3);
            }
        }

    }

    .error-msg {
        color: $SL-ErrorRed;
        font-size: $SL-Error-FS;
        margin-top: .25rem;
    }
}