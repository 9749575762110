/**
 *@file SLInput.scss
 *@brief Reusable input component style
 *@date Feb, 2024
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */
@import "../assets/styles/Variables.scss";

.sl-multiselect {
  margin-bottom: 17px;

  label {
    @include SL-FormLabel;
    margin-bottom: 0.5rem;
  }

  .multiselect-container {
    .searchWrapper {
      background-color: $SL-White;
      padding: 11px 0.75rem;
      @include SL-InputStyle;

      .chip {
        background-color: $SL-BtnYellow;
      }
      .searchBox {
        width: calc(100% - 60px);
      }
    }

    input[type="checkbox"] {
      accent-color: $SL-BtnYellow;
    }

    li {
      &:hover {
        background: $SL-TableHover;
        color: $SL-DarkBlue;
      }
      &.groupHeading {
        color: $SL-DarkBlue;
        font-weight: $SL-Bold;
      }
    }

    &.form-error {
      .searchWrapper {
        border-color: $SL-ErrorRed;

        &:focus {
          box-shadow: 0 0 0 0.25rem rgba($color: $SL-ErrorRed, $alpha: 0.3);
        }
      }
    }

    &.selected-buildings {
      input[placeholder],
      [placeholder],
      *[placeholder] {
        color: $SL-Tooltip-BG !important;
        opacity: 1 !important;
        font-weight: $SL-Bold;
      }

      :-moz-placeholder {
        opacity: 1;
      }

      ::-moz-placeholder {
        opacity: 1;
      }
    }
  }

  .error-msg {
    color: $SL-ErrorRed;
    font-size: $SL-Error-FS;
    margin-top: 0.25rem;
  }
}
