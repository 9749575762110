@import '../assets/styles/Variables.scss';

.login-footer {
    background: $SL-DarkBlue;
    padding: 45px 80px;
    color: $SL-FooterGrey;
    font-size: $SL-Menu-FS;

    @media (max-width:576px) {
        padding: 20px;
    }

    h4 {
        font-size: $SL-Primary-FS;
        color: $SL-White;
        font-weight: $SL-Bold;
        margin-bottom: 50px;

        @media (max-width:576px) {
            margin-bottom: 20px;
        }

    }

    .about {
        p {
            padding: 25px 110px 0 0;

            @media (max-width:576px) {
                padding: 25px 25px 0 0;
            }

            a {
                color: $SL-White;

                &:hover {
                    color: $SL-BtnYellow;
                }
            }
        }
    }

    .navigation {
        @media (max-width:576px) {
            padding-top: 20px;
        }

        a {
            color: $SL-FooterGrey;
            display: block;
            line-height: 31px;
            width: fit-content;

            &:hover {
                color: $SL-BtnYellow;
            }
        }

    }

    .get-in-touch {
        @media (max-width:576px) {
            padding-top: 20px;
        }

        span {
            padding-left: 40px;

            &.mailing-address {
                background: url(../assets/img/ICAddress.svg)0 4px no-repeat;
            }

            &.email {
                background: url(../assets/img/ICMail.svg)0 4px no-repeat;
                margin: 35px 0;

                a {
                    color: $SL-FooterGrey;

                    &:hover {
                        color: $SL-BtnYellow;
                    }
                }
            }

            &.phone {
                background: url(../assets/img/ICPhone.svg)0 4px no-repeat;
            }

        }
    }

    .social {
        @media (max-width:576px) {
            padding-top: 20px;
        }

        span {
            background: rgba($color: $SL-White, $alpha: .07);
            width: 42px;
            height: 42px;
            transition: 0.3s;
            border-radius: 10px;
            margin-right: 15px;

            &:hover {
                cursor: pointer;
                background: rgba($color: $SL-White, $alpha: .32);
            }
        }
    }

    .login-copyright {
        border-top: 1px solid rgba($color: $SL-CopyrightBorder, $alpha: .5);
        font-size: $SL-Copyright-FS;
        margin-top: 40px;
        padding-top: 40px;
    }

}